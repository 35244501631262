import CoverageQuestions from "./CoverageQuestions";
import PreExistingQuestion from "./PreExistingQuestion";
import GettingReimbursed from "./GettingReimbursed";
import PetEligible from "./PetEligible";
import filingClaims from "./FilingClaims";
import ManagingPolicy from "./ManagingPolicy";
import Billing from "./Billing";
import ReferFriend from "./ReferFriend";
import PriorMedicalTreatment from "./PriorMedicalTreatment";

export const FrequentlyAskedQuestions = [
  {
    title: "General Coverage Questions",
    id: "plancoverage",
    questions: [
      {
        title: "1. Who is the insurance carrier for the Healthy Paws pet insurance Plan?",
        detail: () => {
          return CoverageQuestions.whoIsTheInsuranceCarrier();
        },
        id: "Who_is_insurance_carrier",
        section_id: 1,
      },
      {
        title: "2. What is insured under this pet insurance plan?",
        detail: (policyFormUrl, state, questionOpen, stateProperty) => {
          return CoverageQuestions.whatIsCoveredAnswer(policyFormUrl, state, stateProperty);
        },
        id: "What_is_covered",
      },
      {
        title: "3. Does the plan have any waiting periods?",
          detail: (policyFormUrl, state, questionOpen, stateProperty) => {
              return CoverageQuestions.doYouHaveWaitingPeriod(policyFormUrl, state, stateProperty);
        },
        id: "Waiting_Period",
      },
      {
        title: "4. Are there any types of caps or maximum limits?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.maximumLimitAnswer(policyFormUrl);
        },
        id: "Are_there_any_types_of_caps_or_maximum_limits",
      },
      {
        title: "5. Does the plan cover hereditary and congenital conditions?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.heridityConditionCovered(policyFormUrl);
        },
        id: "Hereditary_and_congenital_conditions_are_covered.",
      },
      {
        title: "6. Does the plan cover hip dysplasia?",
        detail: (policyFormUrl, state) => {
          return CoverageQuestions.didYouCoverHipDysplasia(policyFormUrl, state);
        },
        id: "cover_hip_dysplasia",
      },
      {
        title: "7. What is not covered?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.whatIsNotCovered(policyFormUrl);
        },
        id: "What_is_not_covered",
      },
      {
        title: "8. Does the plan cover wellness & preventative care?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.wellness(policyFormUrl);
        },
        id: "wellness_preventive_care",
      },
      {
        title: "9. Is there coverage for dental healthcare that is not preventative?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.dentalHealth(policyFormUrl);
        },
        id: "dentalHealth",
      },
      {
        title: "10. Why doesn't the plan cover the office visit (examination fee)?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.dontCoverOfficeVisit(policyFormUrl);
        },
        id: "dont_you_cover_the_office_visit",
      },
      {
        title: "11. What breed do I select when enrolling my pet?",
        detail: () => {
          return CoverageQuestions.whatBreedToChoose();
        },
        id: "breed_to_choose",
      },
      {
        title: "12. Does the plan cover prescription diets?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.rxDeits(policyFormUrl);
        },
        id: "rx_diets",
      },
      {
        title: "13. Does the plan cover euthanasia and burial?",
        detail: (policyFormUrl) => {
          return CoverageQuestions.euthanasiaBurial(policyFormUrl);
        },
        id: "euthanasia_burial",
      },
    ],
  },
  {
    title: "Pre-existing Conditions",
    id: "PreExistingConditions",
    questions: [
      {
        title: "1. What is a pre-existing condition?",
        detail: (policyFormUrl, state, questionOpen, stateProperty) => {
          return PreExistingQuestion.preExsisitingCondition(policyFormUrl, state, stateProperty);
        },
        id: "pre-existing_condition",
      },
      {
        title: "2. Is my pet's congenital condition considered pre-existing?",
        detail: (policyFormUrl) => {
          return PreExistingQuestion.preExsisitingConditionConsideration(
            policyFormUrl
          );
        },
        id: "pre-existing_condition_consideration",
      },
      {
        title: "3. Are cruciate ligament (CL, CCL, ACL) injuries covered?",
        detail: (policyFormUrl) => {
          return PreExistingQuestion.preExsisitingConditionCoverage(policyFormUrl);
        },
        id: "pre-existing_condition_coverage",
      },
    ],
  },
  {
    title: "Concerns Regarding Your Pet's Prior Medical Treatment",
    id: "PriorMedicalTreatment",
    questions: [
      {
        title: "1. Lumps, bumps, and growths",
        detail: (policyFormUrl, State, questionOpen) => {
          return PriorMedicalTreatment.lumps(policyFormUrl, questionOpen);
        },
        id: "lumps",
      },
      {
        title: "2. Intestinal parasites",
        detail: (policyFormUrl) => {
          return PriorMedicalTreatment.intestinalParasites(policyFormUrl);
        },
        id: "intestinal_parasites",
      },
      {
        title: "3. Ear infections",
        detail: (policyFormUrl) => {
          return PriorMedicalTreatment.earInfections(policyFormUrl);
        },
        id: "ear_infections",
      },
      {
        title: "4. Urinary blockage",
        detail: (policyFormUrl) => {
          return PriorMedicalTreatment.urinaryBlockage(policyFormUrl);
        },
        id: "Urinary_blockage",
      },
      {
        title: "5. Urinary tract infection in cats",
        detail: (policyFormUrl) => {
          return PriorMedicalTreatment.urinaryInfection(policyFormUrl);
        },
        id: "urinary_infection",
      },
      {
        title: "6. Urinary tract infection in dogs",
        detail: (policyFormUrl) => {
          return PriorMedicalTreatment.urinaryDogs(policyFormUrl);
       },
       id: "urinary_dogs"
      },
    ],
  },
  {
    title: "Is My Pet Eligible?",
    id: "PetEligiblity",
    questions: [
      {
        title: "1. Does my pet need a vet exam to qualify for coverage?",
        detail: (policyFormUrl, userAddressState) => {
          return PetEligible.vetExam(policyFormUrl, userAddressState);
        },
        id: "vet_exam",
      },
      {
       title: "2. I just rescued a pet from a shelter. Can I use their physical exam as the enrollment vet exam?",
       detail: (policyFormUrl, stateAbbreviation) => {
         return PetEligible.petExam(policyFormUrl, stateAbbreviation);
       },
       id: "pet_exam"
      }      
    ],
  },
  {
    title: "Filing Claims",
    id: "submittingclaims",
    questions: [
      {
        title: "1. What should I expect in the event of an illness or accident?",
        detail: (policyFormUrl) => {
          return filingClaims.illness(policyFormUrl);
        },
        id: "illness",
      },
      
      {
        title: "2. How do I file a claim?",
        detail: (policyFormUrl) => {
          return filingClaims.fileClaim(policyFormUrl);
        },
        id: "file-claim",
      },
      
      {
        title: "3. How can you contact Healthy Paws about a claim?",
        detail: (policyFormUrl) => {
          return filingClaims.contact(policyFormUrl);
        },
        id: "contact",
      },
      {
        title: "4. Should I file a claim if the invoice total is less than my deductible?",
        detail: (policyFormUrl) => {
          return filingClaims.deductible(policyFormUrl);
        },
        id: "should-claim",
      },
      {
        title: "5. How is reimbursement calculated? ",
        detail: (policyFormUrl) => {
          return filingClaims.reimbursement(policyFormUrl);
        },
        id: "reimbursement",
      },
      {
        title: "6. What medical records do you need for my pet?",
        detail: (policyFormUrl) => {
          return filingClaims.medicalRecords(policyFormUrl);
        },
        id: "medical-records",
      },
    ],
  },

  {
    title: "Getting Reimbursed",
    id: "GettingReimbursed",
    questions: [
      {
        title: "1. What are my options for reimbursement?",
        detail: (policyFormUrl) => {
          return GettingReimbursed.optionReimbrushment(policyFormUrl);
        },
        id: "What_options_for_reimbursement",
      },
      {
        title: "2. How long does it take to get reimbursed?",
        detail: (policyFormUrl) => {
          return GettingReimbursed.howLongItGetReimbursed(policyFormUrl);
        },
        id: "How_long_it_get_reimbursed",
      },
      {
        title: "3. Can my veterinarian receive claims payments directly?",
        detail: (policyFormUrl) => {
          return GettingReimbursed.cantWaitReimbrushment(policyFormUrl);
        },
        id: "What_cant_wait_for_reimbursement",
      },
    ],
  },

  {
    title: "Managing your policy",
    id: "policymanagement",
    questions: [
      
      {
        title: "1. How do I add a new pet?",
        detail: (policyFormUrl) => {
          return ManagingPolicy.addPet(policyFormUrl);
        },
        id: "add_new_pet",
      },
      {
        title: "2. How does the annual deductible work?",
        detail: (policyFormUrl) => {
          return ManagingPolicy.annualDeductible(policyFormUrl);
        },
        id: "annual_deductible",
      },
      {
        title: "3. Can I change my deductible or reimbursement level after enrolling?",
        detail: (policyFormUrl) => {
          return ManagingPolicy.afterEnroll(policyFormUrl);
        },
        id: "after_enroll",
      },
      {
        title: "4. How can I lower the cost of my premiums?",
        detail: () => {
          return ManagingPolicy.howToLowerPremiums();
        },
        id: "lower_premiums",
      },
    ],
  },

  {
    title: "Billing",
    id: "Billing",
    questions: [
      {
        title: "1. How do I update my profile or billing information?",
        detail: (policyFormUrl) => {
          return Billing.updateProfile(policyFormUrl);
        },
        id: "update_profile",
      },
      {
        title: "2. When will the monthly payment be charged or deducted?",
        detail: (policyFormUrl) => {
          return Billing.charged(policyFormUrl);
        },
        id: "charged",
      },
      {
        title: "3. What payment methods do you accept?",
        detail: (policyFormUrl) => {
          return Billing.paymentMethod(policyFormUrl);
        },
        id: "payment_method",
      },
      {
        title: "4. Will my premiums increase?",
        detail: (policyFormUrl) => {
          return Billing.premiumIncrease(policyFormUrl);
        },
        id: "premium_increase",
      },
      {
        title: "5. How do I cancel my policy?",
        detail: () => {
          return Billing.cancelPolicy();
        },
        id: "cancel_policy",
      },
    ],
  },
  
  
  
];
