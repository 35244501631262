import { axiosInstanceOldAPI, axiosInstanceWithAuth } from "./axios";
import { isValidURL } from "./../modules/common/util/util";
import { getCredentialsIfValid } from "./auth/auth";

// import { availablePolicyOptions } from "./mock/mock-available-policy-options";

export const getPetsByAccount = () => {
  let url = "/Pets/GetPetsByAccount";

  let credentials = getCredentialsIfValid();

  let data = {
    EmailAddress : credentials.username
  }
  
      return axiosInstanceWithAuth
      .post(url, data)
      .then(response => {
        let pets = response.data.Items;
        pets.sort((pet1, pet2) => {
          let date1 = new Date(pet1.EnrollmentStartDate);
          let date2 = new Date(pet2.EnrollmentStartDate);
          return date2 - date1;
        });
        return pets;
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
};

//Breed types for pet - New API
export const getBreeds = () => {
  let url = "/breeds";
  return axiosInstanceWithAuth 
    .get(url)
    .then(response => {
      let breeds = response.data.breeds;
      breeds.sort((breed1, breed2) => {
        if (breed1.name === breed2.name) return 0;
        if (breed1.name < breed2.name) return -1;
        if (breed1.name > breed2.name) return 1;
      })
      return breeds;
    })
    .catch(error => {
      let err = {
        errorCode: null,
        errorDescription: null,
        errorMessage: null
      };
      return Promise.reject(err);
    })
}

//Add Pet - Step 1
export const getPolicyOptions = (petDetails) => {
  let url = "/Pets/PetPolicies";
  let data = {
    "pet": {
      "pet_name": petDetails.petName,
      "species": petDetails.petType,
      "gender": petDetails.petGender,
      "breed_id": petDetails.breed,
      "date_of_birth": (Number(petDetails.birthMonth) + 1 ) + "/01/"  + petDetails.birthYear,
      "last_vet_exam_specified": petDetails.vetExam === "yes" ? true : false,
      "affId":petDetails.affId
    }
  }

  return axiosInstanceWithAuth 
    .post(url, data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      let err = {
        errorCode: error.response.data.code,
        errorDescription: null,
        errorMessage: error.response.data.message
      };
      return Promise.reject(err);
    });
}

//Add Pet - Step 3 - Adding a Pet with policy option
export const addPet = (petDetails, policyDetails) => {
  let url = "/Pets/PetPolicy";
  let data = {
    "pet": {
      "pet_name": petDetails.petName,
      "species": petDetails.petType,
      "gender": petDetails.petGender,
      "breed_id": petDetails.breed,
      "date_of_birth": (Number(petDetails.birthMonth) + 1 ) + "/01/"  + petDetails.birthYear,
      "last_vet_exam_specified": petDetails.vetExam === "yes" ? true : false
    },
    "policy": policyDetails
  }
  return axiosInstanceWithAuth 
    .post(url, data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      let err = {
        errorCode: null,
        errorDescription: null,
        errorMessage: null,
        data: error
        }; 
      return Promise.reject(err);
    });
}

// petID - String, required
// VetClinic - string, required
export const updateVetClinic = (petID, VetClinic) => {
  let url = "/Pets/UpdatePet";

  let data = {
    "PetID": petID,
    "CustomerProvidedVetClinic": VetClinic
  };

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

// petID - String, required
// dateJoinedFamily - ISO date string 
export const updatePetJoinedFamily = (petID, dateJoinedFamily) => {
  let url = "/Pets/UpdatePet";

  let data = {
    "PetID": petID,
    "DateJoinedFamily": dateJoinedFamily
  };

  // let promise = new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       isSuccess: true,
  //     })
  //   }, 3000);  
  // });
  // return promise;


  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

/**
 * API call for update pet Image URL
 * @params petID - pet id.
 * @params imageURL - url of image/null. null is for delete photo;
 */
export const updatePetPhoto = (petID, imageURL) => {
  let url = "/Pets/UpdatePhoto";

  let data = {
    "PetID": petID,
    "PetImageURL": imageURL
  };

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error)
    });
};


export const checkImageURL = petDetails => {
  if(petDetails.PetImageURL && isValidURL(petDetails.PetImageURL)){
    return axiosInstanceWithAuth
    .get(petDetails.PetImageURL)
    .then(() => {
      petDetails.ImageAvailable = true;
      return { petDetails };
    })
    .catch(function() {
      //TODO:Need to re-look this logic
      if (petDetails.PetImageURL.includes("amazonaws")) {
        petDetails.ImageAvailable = true;
      } else {
        petDetails.ImageAvailable = false;
      }
      //petDetails.ImageAvailable = false;
      return petDetails;
    });
  }
  else {
    petDetails.ImageAvailable = false;
    return petDetails;
  }  
};

//ChangePolicy options - fetching available policy options
export const fetchAvailableOptions = (data) => {
  const url = "/pets/policyoption";
  let pets = {
    "pets" : data
  }
  return axiosInstanceWithAuth
    .post(url, pets)
    .then(response => 
      response.data
      // availablePolicyOptions[0]
      )
    .catch(error => {
      let err = {
        errorCode: null,
        errorDescription: null,
        errorMessage: null
      };
      return Promise.reject(err);
    });
}



//ChangePolicy options - updating policy changes 
export const getAnniversaryPolicyOptions = () => {
  const url = "/Pets/AnniversaryPolicyOptions";

  return axiosInstanceWithAuth
    .post(url, {})
    .then(response => response.data)
    .catch(error => {
      let err = {
        errorCode: null,
        errorDescription: null,
        errorMessage: null
      };
      return Promise.reject(err);
    });
}

//ChangePolicy options - updating policy changes 
export const policyChanges = (policyChanges, isAnniversary) => {
  const url = "/Pets/PetPolicies";
  let newPolicyDetails = {
    "policy_changes": policyChanges,
    "is_anniversary": isAnniversary
  }
  return axiosInstanceWithAuth
    .put(url, newPolicyDetails)
    .then(response => response.data)
    .catch(error => {
      let err = {
        errorCode: null,
        errorDescription: null,
        errorMessage: null
      };
      return Promise.reject(err);
    });
}