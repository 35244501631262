import React, { Component } from "react";
import CacheBuster from 'react-cache-buster';
import packageInfo  from '../package.json';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Switch } from "react-router-dom";
import { setNewRoute } from "./store/actions/alerts-actions";
import {
  checkCanUserEdit,
  updateUserAccountInfo
} from "./modules/common/util/AccountsUtil";
import { bindActionCreators } from "redux";
import { setLoginState, setReturnURL } from "./store/actions/login-actions";
import SessionExpiryModalDialog from "./modules/common/session-expired/SessionExpired";
import { logOut } from "./store/actions/login-actions";
// import
import HomePage from "./modules/home/HomePage";
import ViewYourProfile from "./modules/view-profile/ViewProfile";
import LoginPage from "./modules/login/LoginPage";
// import FormStyles from "./modules/common/form-fields/FormStyles";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import PublicRoute from "./routes/PublicRoute";
import MobileAppRoute from "./routes/MobileAppRoute";
import UpdatePassword from "./modules/account/update-password/UpdatePassword";
import FileClaim from "./modules/claims/file-claim/FileClaim";
import UpdateBilling from "./modules/account/update-billing/UpdateBilling";
import MobileAppUpdateBilling from "./modules/account/update-billing/MobileAppUpdateBilling";
import ViewClaimStatus from "./modules/claims/claim-status/ViewClaimStatus";
import UploadMissingDocs from "./modules/claims/upload-missing-docs/UploadMissingDocs";
import UpdateReimbursement from "./modules/account/update-reimbursement/UpdateReimbursement";
import AnniversaryCoverageOptions from "./modules/pet-policies/anniversary-coverage-options/AnniversaryCoverageOptions";
import UpdateProfile from "./modules/account/update-profile/UpdateProfile";
import AddPet from "./modules/pet-policies/add-pet/AddPet";
import MobileAppAddPet from "./modules/pet-policies/add-pet/MobileAppAddPet";
import ChangePolicy from "./modules/pet-policies/change-policy-options/ChangePolicy";
//import PostponePayment from "./modules/postpone-payment/PostponePayment";
import UploadMedicalRecords from "./modules/claims/upload-medical-records/UploadMedicalRecords";
import OopsLostPage from "./modules/oops/OopsLostPage";
import GetMobileApp from "./modules/get-mobile-app/GetMobileApp";
import FAQ from "./modules/faq/faq";
import PolicyDocs from "./modules/policy-docs/PolicyDocs";
import SalesForceLink from "./modules/common/salesForceLink/SalesForceLink";
import Contact from "./modules/contact/contact";
import PetDetails from "./modules/pet-details/PetDetails";
import ChangeAddress from "./modules/change-address/ChangeAddress";
import ChangeMailingAddress from "./modules/change-mailing-address/ChangeMailingAddress";
import RaFTermsConditions from "./modules/terms-conditions/RaFTermsConditions";
import CancelPolicy from "./modules/cancel-policy/cancel-policy";
import PostCancelPolicy from "./modules/post-cancel-policy/PostCancelPolicy";
import RetryBillingSuccess from "./modules/account/retry-billing/RetryBillingSuccess";
import NewMailingAddress from "./modules/change-mailing-address/UpdateMailingAddress";
import CCTLogin from "./modules/cct-login/CCTLogin";
import EOBLink from './modules/common/salesForceLink/EOBLink';
import ARNLink from './modules/common/salesForceLink/ARNLink';
import SalesForceContentLink from "./modules/common/salesForceLink/SalesForceContentLink";
import { AppLinks, PageTitles } from "./constants/app-links";

import Loader from "./modules/common/loader/Loader";

import { getCredentialsIfValid, saveCredentials } from "./api/auth/auth";
import { validateSession, decryptOAuthToken } from "./api/auth-api";
import { getAccountInfo } from "./api/accounts-api";

import "./App.css";

class App extends Component {
  state = {
    displayTopBanner:false
  }
  disableAlertCreated = false;
  //We are setting location for alerts, which are not matching with actual routes
  //[Alternate] We can change the route itself, but it will cause rerendering of the route component
  //This way only route component is rerender
  // We need to set different location for step1 of below routes by calling setNewRoute action in respective component
  setLocationForAlert = () => {
    const pathName = this.props.location.pathname.toLocaleLowerCase();
    if (pathName) {
      if (PageTitles[pathName]) document.title = PageTitles[pathName];
      else document.title = PageTitles["default"];
    } else {
      document.title = PageTitles["default"];
    }
      switch (pathName) {
      case "/mobileappaddpet":
        this.props.actions.setNewRoute("/MobileAppAddPet/Step1");
        return;
      case "/addpet":
        this.props.actions.setNewRoute("/AddPet/Step1");
        return;
      case "/changepolicyoptions":
        this.props.actions.setNewRoute("/ChangePolicyOptions/Step1");
        return;
      //case "/postponepayment":
      //  this.props.actions.setNewRoute("/PostponePayment/Step1");
      //  return;
      case "/cancelpolicy":
        this.props.actions.setNewRoute("/CancelPolicy/Step1");
        return;
      default:
        this.props.actions.setNewRoute(this.props.location.pathname);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setLocationForAlert();
      if (this.props.userDetails) {
        //Checks for billing day and login time
        //Disable some features if the login time falls on Sales Force script run time
        checkCanUserEdit(this.props.userDetails.BillingDay);
      }
    }
  }

  async componentDidMount() {
    let authToken = '';
    
    try {
      // Fetch URL Parameter
      //This is check for Return URL query param
      var input = this.props.location.search.trim().replace(/^[?#&]/, "");

      if (
        this.props.location &&
        this.props.location.pathname &&
        this.props.location.pathname.toLowerCase() === "/login" &&
        this.props.location.search
      ) {
        for (const param of input.split("&")) {
          let [key, value] = param.split("=");
          if (key && key.toLowerCase() === "returnurl") {
            this.props.actions.setReturnURL(true);
            this.props.history.push(value);
          }
        }
      } else if (
        this.props.location &&
        this.props.location.pathname &&
        (this.props.location.pathname.toLowerCase() === "/mobileappupdatebillinginfo" || 
        this.props.location.pathname.toLowerCase() === "/mobileappaddpet"  || 
        this.props.location.pathname.toLowerCase() === "/updateaddressmobile" ||
        this.props.location.pathname.toLowerCase().includes("/eob/") ||
        this.props.location.pathname.toLowerCase().includes("/mobileeob/") ||
        this.props.location.pathname.toLowerCase().includes("/arn/")) &&
        this.props.location.search
      ) {
        for (const param of input.split("&")) {
          let [key, value] = param.split("=");
          if (key && key.toLowerCase() === "token") {
            authToken = value;
          }
        }
      }

      // if an auth-token is passed, get the related credentials from the service and store them if available
      if (authToken) {
        localStorage.setItem("auth-token", authToken);
        let decodedTokenResponse = await decryptOAuthToken(authToken);

        let credentials = {
          username: decodedTokenResponse.user_name,
          password: '',
          rememberMe: '',
        };

        saveCredentials(credentials, decodedTokenResponse);
      }

      // Check is credentials are available.
      let credentials = getCredentialsIfValid();
      if (credentials || authToken) {
        // Validate Token
        validateSession(credentials)
          .then(() => {
            getAccountInfo()
              .then(accountResponse => {
                //As we depends on some properties if user account details
                //We are setting login to true only after successful useraccount fetch
                if (accountResponse) {
                  updateUserAccountInfo(accountResponse);
                  this.props.actions.setLoginState(true);

                  // Add the Live Agent customization here, right when app loads because of not being able to call addCustomDetail after page has been loaded
                  // window.liveagent.init('https://d.' + process.env.REACT_APP_LIVEAGENT_DEPLOYMENT_SUBDOMAIN + '.salesforceliveagent.com/chat', process.env.REACT_APP_LIVEAGENT_DEPLOYMENT_SFID, process.env.REACT_APP_SALESFORCE_WEBTOCASE_ORGID);

                  // window.liveagent.addCustomDetail("PersonEmail", accountResponse.PetParentInfo.Email);
                  // window.liveagent.findOrCreate("Account").map("PersonEmail", "PersonEmail", true, true, false);
                  // window.liveagent.findOrCreate("Account").saveToTranscript("AccountId").showOnCreate()
                  //     .linkToEntity("Case", "AccountId");    

                } else {
                  this.props.actions.setLoginState(false);
                }
              })
              .catch(() => {
                this.props.actions.setLoginState(false);
                window.scrollTo(0, 0);
              });
          })
          .catch(error => {
            this.props.actions.setLoginState(false);
            window.scrollTo(0, 0);
          });
      } else {
        this.props.actions.setLoginState(false);
      }
      this.setLocationForAlert();
    } catch (error) {
      this.props.actions.setLoginState(false);
    }
  }

  toggleSessionExpiryModal = () => {
    this.props.actions.logOut();
  };

  render() {
    return (
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <div>
          <SessionExpiryModalDialog
            toggleSessionExpiryModal={this.toggleSessionExpiryModal}
            showSessionExpiryModal={this.props.loginExpired}
          />

          {this.props.loggedIn !== null ? (
            <div>
              <Switch>
                <AuthenticatedRoute
                  exact
                  path="/"
                  loggedIn={this.props.loggedIn}
                  component={HomePage}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.viewProfile}
                  loggedIn={this.props.loggedIn}
                  component={ViewYourProfile}
                />
                {/* <PublicRoute
                  exact
                  path="/forms"
                  loggedIn={this.props.loggedIn}
                  component={FormStyles}
                /> */}
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.changeProfile}
                  loggedIn={this.props.loggedIn}
                  component={UpdateProfile}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.changePassword}
                  loggedIn={this.props.loggedIn}
                  component={UpdatePassword}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.claims.fileClaim}
                  loggedIn={this.props.loggedIn}
                  component={FileClaim}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.updateBillingInfo}
                  loggedIn={this.props.loggedIn}
                  component={UpdateBilling}
                />
                <MobileAppRoute
                  path={AppLinks.policy.mobileAppAddPet}
                  loggedIn={this.props.loggedIn}
                  component={MobileAppAddPet}
                />
                <MobileAppRoute
                  path={AppLinks.account.mobileAppUpdateBillingInfo}
                  loggedIn={this.props.loggedIn}
                  component={MobileAppUpdateBilling}
                />
                <MobileAppRoute
                  path={AppLinks.account.mobileAppChangePhysicalAddress}
                  loggedIn={this.props.loggedIn}
                  component={ChangeAddress}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.changePhysicalAddress}
                  loggedIn={this.props.loggedIn}
                  component={ChangeAddress}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.claims.claimStatus}
                  loggedIn={this.props.loggedIn}
                  component={ViewClaimStatus}
                />
                <AuthenticatedRoute
                  path={AppLinks.claims.updateClaim}
                  loggedIn={this.props.loggedIn}
                  component={UploadMissingDocs}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.updateReimbursement}
                  loggedIn={this.props.loggedIn}
                  component={UpdateReimbursement}
                />
                {/* <AuthenticatedRoute
                  exact
                  path={AppLinks.account.updateMailingAddress}
                  loggedIn={this.props.loggedIn}
                  component={NewMailingAddress}
                /> */}
                <AuthenticatedRoute
                  exact
                  path={AppLinks.account.updateMailingAddress}
                  loggedIn={this.props.loggedIn}
                  component={ChangeMailingAddress}
                /> 
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.anniversaryCoverageOptions}
                  loggedIn={this.props.loggedIn}
                  component={AnniversaryCoverageOptions}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.claims.uploadMedicalRecords}
                  loggedIn={this.props.loggedIn}
                  component={UploadMedicalRecords}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.claims.getMobileApp}
                  loggedIn={this.props.loggedIn}
                  component={GetMobileApp}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.faq.faq}
                  loggedIn={this.props.loggedIn}
                  component={props => <FAQ time={new Date()} {...props} />}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.others.contact}
                  loggedIn={this.props.loggedIn}
                  compState = {this.state}
                  component={props => <Contact time={new Date()} {...props} />}
                />

                <AuthenticatedRoute
                  exact
                  path={AppLinks.others.RAFTermsAndConditions}
                  loggedIn={this.props.loggedIn}
                  component={RaFTermsConditions}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.viewPetDetails}
                  loggedIn={this.props.loggedIn}
                  component={PetDetails}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.viewPolicyDocuments}
                  loggedIn={this.props.loggedIn}
                  component={PolicyDocs}
                />
                {/*<PublicRoute
                  exact
                  path={AppLinks.policy.file} 
                  component={SalesForceLink}
                />*/}              
                {/* This route works only for EOB file link */}
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.eobFileLink}  
                  loggedIn={this.props.loggedIn}              
                  component={SalesForceLink}
                />
                <MobileAppRoute
                  path={AppLinks.policy.mobileEOBFileLink}
                  loggedIn={this.props.loggedIn}
                  component={SalesForceLink}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.file}
                  loggedIn={this.props.loggedIn}
                  component={SalesForceLink}
                />
              
                <AuthenticatedRoute
                  path={AppLinks.policy.pubfile}
                  loggedIn={this.props.loggedIn}
                  component={SalesForceLink}
                />
                {/*<AuthenticatedRoute
                  exact
                  path={AppLinks.others.eobLink}
                  loggedIn={this.props.loggedIn}
                  component={EOBLink}
                />*/}
                <PublicRoute
                  exact
                  path={AppLinks.others.eobLink}
                  component={EOBLink}
                />
                <PublicRoute
                  path={AppLinks.others.arnLink}
                  component={ARNLink}
                />

              <AuthenticatedRoute
                exact
                path={AppLinks.others.contentDocumentLink}     
                loggedIn={this.props.loggedIn}
                userAccountDetails={this.props.userAccountDetails}
                component={SalesForceContentLink}
              />
              {/*<AuthenticatedRoute
                  exact
                  path={AppLinks.others.postponePayment}
                  loggedIn={this.props.loggedIn}
                  component={PostponePayment}
                />*/}
                <PublicRoute path={AppLinks.others.login} component={LoginPage} />
                <PublicRoute
                  path={AppLinks.others.cctlogin}
                  component={CCTLogin}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.addPet}
                  loggedIn={this.props.loggedIn}
                  component={AddPet}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.policy.changePolicyOptions}
                  loggedIn={this.props.loggedIn}
                  component={ChangePolicy}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.others.cancelPolicy}
                  loggedIn={this.props.loggedIn}
                  component={CancelPolicy}
                />
                <PublicRoute
                  exact
                  path={AppLinks.others.postCancelPolicy}
                  component={PostCancelPolicy}
                />
                <AuthenticatedRoute
                  exact
                  path={AppLinks.others.retryBillingSuccess}
                  loggedIn={this.props.loggedIn}
                  component={RetryBillingSuccess}
                />
                <AuthenticatedRoute
                  loggedIn={this.props.loggedIn}
                  component={OopsLostPage}
                />
              </Switch>
            </div>
          ) : (
            <Loader position="absolute" />
          )}
        </div>
      </CacheBuster>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
  loginExpired: state.login.loginExpired,
  userDetails: state.account.userAccountDetails
});

//Wrap all the actions with dispatcher
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setLoginState,
      logOut,
      setNewRoute,
      setReturnURL
    },
    dispatch
  )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
