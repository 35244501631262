import React from "react";
import {AppLinks, FAQLinks} from "../../../constants/app-links"

export default class filingClaims {
    static waitingPeriod = (policyFormUrl) => {
        return (
            <div className="faqAnswer" >
                <p>There is a waiting period of 15-days after your policy effective date. Your policy is effective 12:01 a.m. the day <span className="bold">after</span> you enroll your pet(s) with Healthy Paws Pet Insurance. You can submit claims for accidents &amp; illnesses that occur after the 15-day waiting period. </p>
                <p>Hip dysplasia coverage is provided at no extra cost and is subject to a 12-month waiting period. For more information on hip dysplasia click <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">here</a>.</p>
                <p style={{ fontStyle: "italic" }}>Waiting periods do not apply to the states of Maryland and New Hampshire. For additional information on these states click <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">here</a>.</p>
                <p>
                    For additional information please <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">click here</a> to see policy specific provisions related to this FAQ.
		        </p>
            </div>
        )
    };
    static illness = () => {
        return (
            <div className="faqAnswer" >
                <p>
                    You can expect:
                    </p>
                <ul className="blackdisc">
                    <li>You have the option to choose any licensed veterinarian. This includes specialists and emergency animal hospitals.</li>
                    <li>After paying for the treatment in full, submit a copy of your paid invoice to Healthy Paws for review.</li>
                </ul>
                <p>
                    While you're helping your pet get better, we'll work on getting your claim processed. 
                </p>
            </div>
        );
    }

    static fileClaim = (policyFormUrl) => {
        return (
            <div className="faqAnswer">
                <p>
                   <strong>A claim form is not required for claims processing.</strong> You can simply submit
                   your claim by taking a photo of your invoice and uploading it through our
                   online <strong>Customer Center</strong> or <strong>Healthy Paws Mobile App.</strong>
                 </p>
                <p>
                   If you would like a claim form, you can download a pre-populated, personalized
                   form in the <a style={{fontWeight:"600"}} href="https://myaccount.healthypawspetinsurance.com/" target="_blank" rel="noopener noreferrer">My Account</a> section of our website.
                </p>
                <p>
                    If you prefer, you can also submit your invoice(s) and supporting medical
                    documents to <a style={{fontWeight:"600"}} href={"mailto:"+ process.env.REACT_APP_CLAIMS_MAILID}>claims@healthypawspetinsurance.com</a>
                </p>
                <p>
                   fax (888-228-4129), or mail to: <br/>
                   Healthy Paws pet insurance<br/>
                   P.O. Box 50034<br/>
                   Bellevue, WA 98015
                </p>
            </div>
        )
    }

    static contact = () => {
        return (
            <div className="faqAnswer">
                <p>If you have questions you can email us at <a style={{fontWeight:"600"}} href={"mailto:"+ process.env.REACT_APP_CLAIMS_MAILID}>claims@healthypawspetinsurance.com</a> or <a style={{fontWeight:"600"}} href={AppLinks.others.contact} target="_blank" rel="noopener noreferrer">click here</a>.</p>
                <p>We are available Monday-Friday from 7:00am to 4:00pm PT and Saturday 8:00am to 5:00pm PT. 
                We are closed on Sunday and Healthy Paws recognized holidays.
                </p>
            </div>
        );
    }

    static deductible = () => {
        return (
            <div className="faqAnswer" >
                <p>Yes! To maximize the annual deductible, we recommend that you submit all claims pertaining to new accidents, illnesses and injuries.</p>
                <p>The annual deductible is by "coverage term" 
                    which is the twelve (12) month period that begins with the 
                    effective date of coverage and continues for each twelve (12) 
                    month period thereafter.
                </p>
            </div>
        )
    }

    static discount = () => {
        return (
            <div className="faqAnswer" >
                <p>For covered claims, Healthy Paws applies any discounts to the non-covered items in your invoice.</p>
            </div>
        )
    }

    static customerCare = () => {
        return (
            <div className="faqAnswer" >
                <p>We are available Monday-Friday from 7am to 4pm and Saturday from 8am to 5pm Pacific Time. Closed Sunday.</p>
            </div>

        )

    }

    static claimForm = () => {
        return (
            <div className="faqAnswer" >
                <p>
                    A claim form is not required for claims processing. You can simply submit your claim by taking a photo of your invoice and upload it through our online Customer Center or Healthy Paws Mobile App.
                </p>
                 <p>If you would like a claim form, you can download a pre-populated, personalized form in the <span className="disabled-link-wrapper"> <a style={{fontWeight:"600"}} className="disabled-link" href={AppLinks.claims.fileClaim} target="_blank" rel="noopener noreferrer">My Account</a> </span> section of our website.</p>
            </div>

        )
    }

    static reimbursement = (policyFormUrl) => {
        return (
            <div className="faqAnswer" >
                <p>
                    Reimbursements are based on your actual veterinary bills. Claims representatives determine the total of the covered treatments, if any, and multiply that by your reimbursement rate. They then subtract your remaining annual deductible.
                </p>
                <div style={{ width: 350 }}>
                For illustrative purposes only:
                    <table className="ReimbursementCalculationTable">
                        <tbody><tr>
                            <td style={{ textAlign: 'right' }}>$ 1,200</td>
                            <td>&nbsp;</td>
                            <td style={{ paddingLeft: 15, textAlign: 'left' }}>Covered treatments</td>
                        </tr>
                            <tr style={{ borderBottom: '1px solid #4F4F4F'}}>
                                <td style={{textAlign: 'right' }}>x 80%</td>
                                <td>&nbsp;</td>
                                <td style={{ paddingLeft: 15, textAlign: 'left' }}>Your Reimbursement Level</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'right' }}>$ 960</td>
                                <td>&nbsp;</td>
                                <td style={{ paddingLeft: 15, textAlign: 'left' }}>&nbsp;</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #4F4F4F'}}>
                                <td style={{textAlign: 'right' }}>- $ 250</td>
                                <td>&nbsp;</td>
                                <td style={{ paddingLeft: 15, textAlign: 'left' }}>Remaining Annual Deductible</td>
                            </tr>
                            <tr>
                                <td className="font-bold" style={{ textAlign: 'right' }}>$ 710</td>
                                <td>&nbsp;</td>
                                <td className="font-bold" style={{ paddingLeft: 15, textAlign: 'left' }}>Reimbursement Amount</td>
                            </tr>
                        </tbody></table>
                </div>
                <br />
                <p>
                Your pet's deductible is annual, meaning it must be satisfied only once per policy term and resets on the anniversary of your pet's enrollment.                </p>
                <p>
                { policyFormUrl ? (
                    <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                    To review your policy, please click here.
                    </a>
                ) : (
                    <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                    </a>
                )}
                </p>
            </div>

        )
    }

    static medicalRecords = (policyFormUrl) => {
        return (
            <div className="faqAnswer" >
                <p>Medical records for your pet include doctor's notes, laboratory results, and other documentation regarding all of your pet's visits to the veterinarian. These notes are kept by your veterinarian and are not typically sent home with you. If you are filing your first claim, the claims representatives need to review your pet's complete medical records. Please request your pet's full medical records from your veterinary office.</p>
                <p className="disabled-link-wrapper">You can upload medical records or other documentation using the <a style={{fontWeight:"600"}} className="disabled-link" href={AppLinks.claims.uploadMedicalRecords} target="_blank" rel="noopener noreferrer">Upload Medical Records</a> feature in your Customer Center.</p>
                <p>Your veterinarian may also submit these records by emailing to <a style={{fontWeight:"600"}} href={"mailto:" + process.env.REACT_APP_RECORDS_MAILID}>records@healthypawspetinsurance.com</a> or faxing to 844-333-0739.</p>
            </div>
        )
    }

}